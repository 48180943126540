import React from "react"
import Page from "../components/page"
import FAQComponent from "../components/faqComponent"
import styles from "./faq.module.css"
import pageStyles from "../components/page.module.css"

export default function AboutPhototailors({ location }) {
  return (
    <Page title={"FAQ – PhotoTailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
      <div className={styles.row}>
        <div className={styles.item}>
          <FAQComponent
            item={{
              title: "Design", items: [
                {
                  question: "How does your design process work?",
                  answer: (<ol>
                    <li>You choose your album size and cover style in our album shop.</li>
                    <li>You upload your photos while ordering.</li>
                    <li>You pick the cover style and cover material.</li>
                    <li>We start designing your album and send you the album design proof.</li>
                    <li>You review the design proof and request changes as many times as you want until you’re happy
                      with
                      the result.
                    </li>
                    <li>You confirm the design proof.</li>
                    <li>We start making the album and send you a photo after the album is finished.</li>
                    <li>We ship the album and you can track it in your account.</li>
                  </ol>)
                },
                {
                  question: "Can I use my own design?",
                  answer: (<p>Yes, you can upload your own design while ordering from </p>)
                },
                {
                  question: "How long does the design process take?",
                  answer: (
                    <div>
                      <p><strong>Standard</strong> It takes us between 1-2 working days to design your album.</p>
                      <p><strong>Express</strong> It takes us 24 hours to design your album.</p>
                    </div>
                  )
                },
                {
                  question: "What's the maximum number of pages for an album?",
                  answer: (
                    <div>
                      <p>The maximum amount of pages we currently offer is 70 pages (35 spreads).</p>
                    </div>
                  )
                },
                {
                  question: "What's the minimum number of pages for an album?",
                  answer: (
                    <div>
                      <p>The minimum amount of pages we currently offer is 30 pages (15 spreads).</p>
                    </div>
                  )
                },
                {
                  question: "What kind of materials can I choose for my album's cover?",
                  answer: (
                    <div>
                      <p>You can check our available materials <a href="/albums/swatches/">here</a>. You can choose
                        between
                        ecological leather, linen and genuine leather.</p>
                    </div>
                  )
                },
                {
                  question: "Where can I see some of the albums you have made?",
                  answer: (
                    <div>
                      <p>You can see our albums gallery <a href="/albums/gallery/">here</a>. You can filter the gallery
                        by
                        the cover material, style or size.</p>
                    </div>
                  )
                },
                {
                  question: "What cover styles do you have?",
                  answer: (
                    <div>
                      <p>We currently offer a variety of <a href="/albums/cover-styles/">cover styles</a>. You can also
                        see
                        our albums gallery <a href="/albums/gallery/">here</a>&nbsp;and filter them by the cover
                        material,
                        style or size.</p>
                    </div>
                  )
                }
              ]
            }}/>
        </div>
        <div className={styles.item}>
          <FAQComponent
            item={{
              title: "Cost", items: [
                {
                  question: "How much does the album design cost?",
                  answer: (<p>We offer FREE design services for each album.</p>)
                },
                {
                  question: "How much does shipping cost?",
                  answer: (
                    <p>We offer FREE standard shipping services. If you need it sooner you can choose express delivery
                      for an additional $25.</p>)
                },
                {
                  question: "How much does an album cost?",
                  answer: (<p>Each album price varies based on size and number of pages. You can use our <a
                    href="/albums/pricing/">pricing calculator</a> to find out how much an album is going to cost you.
                    If you need more help you can <a href="/contact/">contact us.</a></p>)
                },
                {
                  question: "How much do parents albums cost?",
                  answer: (
                    <p>Parents album are actually duplicates of the same size or smaller albums. Their price has a
                      discount of 30%. If you buy 1 Large album and 1 Small Album you will have a 30% discount for the
                      Small Album.</p>)
                },
                {
                  question: "What's the maximum amount of duplicates?",
                  answer: (
                    <p>There is no maximum amount that you can order for duplicates. You can have as many as you want
                      and each will have 30% discount.</p>)
                }

              ]
            }}/>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.item}>
          <FAQComponent
            item={{
              title: "Shipping", items: [
                {
                  question: "How long does shipping take?",
                  answer: (<div>
                    <p><strong>Standard</strong></p>
                    <ul>
                      <li>Europe: 3-5 working days</li>
                      <li>USA &amp; Canada: 5-8 working days</li>
                      <li>Australia &amp; Asia: 8-12 working days</li>
                      <li>South America: 10-12 working days</li>
                    </ul>
                    <br/>
                    <p><strong>Express</strong></p>
                    <ul>
                      <li>Europe: 1-2 working days</li>
                      <li>USA &amp; Canada: 3-5 working days</li>
                      <li>Australia &amp; Asia: 5-7 working days</li>
                      <li>South America: 5-7 working days</li>
                    </ul>
                  </div>)
                },
                {
                  question: "Can I ship the album to a different address?",
                  answer: (
                    <p>Yes, you can fill out the shipping address when you order your album. Billing and shipping
                      address
                      can be different.</p>
                  )
                },
                {
                  question: "Where do you ship?",
                  answer: (
                    <p>We currently ship to almost every country in Europe, North America, South America, Asia and
                      Australia.</p>
                  )
                }

              ]
            }}/>
        </div>
        <div className={styles.item}>
          <FAQComponent
            item={{
              title: "Orders", items: [

                {
                  question: "What is the turnaround time for my order?",
                  answer: (
                    <div>
                      <p><strong>Standard</strong> It takes us between 3-5 working days to finish your order.</p>
                      <p><strong>Express&nbsp;</strong>It takes us 24 hours to finish your order.</p>
                    </div>
                  )
                },
                {
                  question: "What kind of personalization do you offer?",
                  answer: (
                    <div>
                      <p>You can personalize or customize your album for free using:</p>
                      <br/>
                      <ul>
                        <li>embossing</li>
                        <li>laser engraving</li>
                        <li>UV printing</li>
                      </ul>
                    </div>
                  )
                },
                {
                  question: "Do you offer gift wrapping?",
                  answer: (
                    <p>No, at this time we don’t offer gift wrapping.</p>
                  )
                }

              ]
            }}/>
        </div>
      </div>
        </div>
      </div>
    </Page>


  )
}
