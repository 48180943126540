import React, { useState } from "react"
import styles from "./faq-component.module.css"

export default function FAQComponent({item}) {
  const [isExpanded, setExpanded] = useState(false);

  const getItemsElements = () => {
    return item.items.map(it=>{
      return (
        <div key={it.question} className={styles.item}>
          <div className={styles.head} onClick={()=>setExpanded(it.question)}>
            {(isExpanded !== it.question) &&
            <div className={styles.toggle}></div>
            }
            {(isExpanded === it.question) &&
            <div className={styles.toggleCollapsed}></div>
            }
            <h4 className={styles.title}>{it.question}</h4>
          </div>
          {(isExpanded === it.question) &&
          <div className={styles.answer}>{it.answer}</div>
          }
        </div>
      )
    })
  }

  const itemsElements = getItemsElements();

  return (
    <div className={styles.main}>
      <h3 className={styles.title}>{item.title}</h3>
      <div className={styles.separator}/>
      <div>
        {itemsElements}

      </div>
    </div>
  )
}
